export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/Auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/keluhan',
  //   name: 'keluhan-external',
  //   component: () => import('@/views/pages/Keluhan/External.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //     pageTitle: 'Keluhan',
  //     breadcrumb: [
  //       {
  //         text: 'Tulis Surat Keluar',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/Disposisi.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi',
    name: 'disposisi',
    component: () => import('@/views/pages/Disposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Disposisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perintah',
    name: 'perintah',
    component: () => import('@/views/pages/Perintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Perintah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-keluar',
    name: 'suratKeluar',
    component: () => import('@/views/pages/SuratKeluar.vue'),
    meta: {
      pageTitle: 'Surat Keluar',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data',
    name: 'permintaanData',
    component: () => import('@/views/pages/PermintaanData.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan-internal',
    name: 'keluhanInternal',
    component: () => import('@/views/pages/KeluhanInternal.vue'),
    meta: {
      pageTitle: 'Keluhan Internal',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Keluhan Internal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan-external',
    name: 'keluhanExternal',
    component: () => import('@/views/pages/KeluhanExternal.vue'),
    meta: {
      pageTitle: 'Keluhan External',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Keluhan External',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arsip',
    name: 'arsip',
    component: () => import('@/views/pages/Arsip.vue'),
    meta: {
      pageTitle: 'Arsip',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Arsip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('@/views/pages/Security.vue'),
    meta: {
      pageTitle: 'Security',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Security',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log-kendaraan',
    name: 'kendaraan',
    component: () => import('@/views/pages/LogKendaraan.vue'),
    meta: {
      pageTitle: 'Log Kendaraan',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Log Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import('@/views/pages/Agenda.vue'),
    meta: {
      pageTitle: 'Agenda',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Agenda',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manajemen-akun',
    name: 'user',
    component: () => import('@/views/pages/ManagementAkun.vue'),
    meta: {
      pageTitle: 'Manajemen Akun',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Manajemen Akun',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kejadian-penting',
    name: 'kejadian',
    component: () => import('@/views/pages/KejadianPenting.vue'),
    meta: {
      pageTitle: 'Kejadian Penting',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Kejadian Penting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tanggap-darurat',
    name: 'tanggap-darurat',
    component: () => import('@/views/pages/TanggapDarurat.vue'),
    meta: {
      pageTitle: 'Tanggap Darurat',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Tanggap Darurat',
          active: true,
        },
      ],
    },
  },
]
